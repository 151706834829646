import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { remCalc } from "../../../theme/utils";
import Text from "../Text";

const StyledResponsiveText = styled(Text)`
  font-size: ${props =>
    `calc(${remCalc(props.min)} + (${props.max} - ${
      props.min
    }) * ((100vw - ${remCalc(300)}) / (1024 - 300)))`};
`;

const ResponsiveText = props => {
  const { children } = props;

  return <StyledResponsiveText {...props}>{children}</StyledResponsiveText>;
};

ResponsiveText.defaultProps = {
  min: 16,
  max: 48,
};

ResponsiveText.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ResponsiveText;
