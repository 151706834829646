import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const StyledTitle = styled.h1`
  font-family: var(--title-font);
  font-weight: 700;
  color: ${props => props.theme.colors[props.color] || props.color};
  font-size: ${props => `var(${props.size})`};
  line-height: 1.125;
`;

/**
 * Checks if header level is valid
 * @param {string | number} level Header level
 * @returns {boolean}
 */
const isValidHeader = level => {
  level = Number(level);
  return level >= 0 && level <= 6;
};

/**
 * Generates Header size style
 * @param {boolean} level
 * @returns {string}
 */
const generateHeaderStyle = level => {
  return isValidHeader(level) ? `--h${level}-size` : `--body-regular-size`;
};

/**
 * @param {string | number} level
 * @returns { string}
 */
const generateHeaderElement = level => {
  return isValidHeader(level) ? `h${level}` : "h6";
};

const Title = props => {
  const { level, children } = props;

  const size = generateHeaderStyle(level);
  return (
    <StyledTitle as={generateHeaderElement(level)} size={size} {...props}>
      {children}
    </StyledTitle>
  );
};

Title.defaultProps = {
  color: "inherit",
  level: 6,
};

Title.propTypes = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  children: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Title;
